<template>
  <form @submit.prevent="confirmUpdate">
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <p class="modal-card-title has-text-white has-text-centered">Agents</p>
      </header>

      <section v-if="loading" class="modal-card-body">
        <loading />
      </section>

      <section v-else class="modal-card-body">
        <b-field label="Search">
          <b-input v-model="filterText" type="text" />
        </b-field>

        <section v-if="assignedAgents.length">
          <b-field label="Assigned" />
          <agent-row
            v-for="agent in assignedAgents"
            :key="agent._id"
            :agent-id="agent._id"
            :agent-name="agent.name || agent.email"
            :agent-avatar-url="agent.avatarUrl"
            :is-alpha="alphaAgentId === agent._id"
            :closeable="true"
            @clicked="markAsAlpha(agent._id)"
            @close="removeAgent(agent)"
          />
        </section>

        <section
          v-if="filteredAgents.length"
          class="has-margin-top-200 agent-list"
        >
          <b-field label="Available" />
          <agent-row
            v-for="agent in filteredAgents"
            :key="agent._id"
            :agent-id="agent._id"
            :agent-name="agent.name || agent.email"
            :agent-avatar-url="agent.avatarUrl"
            :allow-add="true"
            @checkChanged="selectAgent($event, agent)"
            @add="addAgent(agent)"
          />
        </section>

        <no-results
          v-else
          icon="users"
          title="No agents"
          message="Oops, we couldn't find any agents"
        />
      </section>

      <footer class="modal-card-foot has-content-justified-between">
        <button
          :disabled="processing"
          type="button"
          class="button is-light"
          tabindex="-1"
          @click="$emit('close')"
        >
          Close
        </button>

        <button
          :class="{ 'is-loading': processing }"
          :disabled="processing || !hasChange"
          type="submit"
          class="button is-success"
        >
          Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import { collection, getDocs, query, where } from "@firebase/firestore";
export default {
  name: "AgentsSelct",
  components: {
    "agent-row": () => import("@shared/tasks/agent/_agentUserRow")
  },
  props: {
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      allAgents: {},
      addedAgents: this.task ? this.task.agents : [],
      alphaAgentId: this.task ? this.task.alphaAgent.id : null,
      selectedAgents: {},
      loading: false,
      processing: false,
      filterText: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    task() {
      return this.$store.getters["tasks/task"](this.taskId);
    },
    agentsData() {
      const data = (this.task.participants || []).reduce((map, id) => {
        return {
          ...map,
          [id]: this.$_.merge(
            {},
            { _id: id, name: id },
            this.$_.get(this.task, `participantsData[${id}]`, {})
          )
        };
      }, {});

      return this.$_.merge({}, data, this.allAgents);
    },
    assignedAgents() {
      return this.$_(this.addedAgents || [])
        .map(agentId => {
          return this.agentsData[agentId]
            ? this.agentsData[agentId]
            : { _id: agentId, name: this.agentsData[agentId].name };
        })
        .orderBy(agent => agent.name || agent.email)
        .value();
    },
    filteredAgents() {
      if (this.$_.isEmpty(this.filterText)) return this.availableAgents;
      return this.$_.filter(this.availableAgents, agent =>
        (agent.name || agent.email)
          .toLowerCase()
          .includes(this.filterText.toLowerCase())
      );
    },
    availableAgents() {
      return this.$_(this.allAgents)
        .toArray()
        .differenceBy(this.assignedAgents, "_id")
        .orderBy(agent => agent.name || agent.emal)
        .value();
    },
    hasChange() {
      return (
        this.$_.xor(this.task.agents, this.addedAgents).length > 0 ||
        (!!this.task.alphaAgent && this.task.alphaAgent.id != this.alphaAgentId)
      );
    }
  },
  created() {
    this.getAgents();
  },
  methods: {
    markAsAlpha(agentId) {
      this.alphaAgentId = agentId;
    },
    getAgents() {
      this.loading = true;
      return getDocs(
        query(
          collection(this.$firestore, "users"),
          where("isAgent", "==", true)
        )
      )
        .then(snapshot => {
          snapshot.forEach(user => {
            this.$set(
              this.allAgents,
              user.id,
              this.$_.merge({}, user.data(), { _id: user.id })
            );
          });

          this.loading = false;

          this.addedAgents = (this.task.agents || []).map(a => a);
          this.alphaAgentId = this.task.alphaAgent
            ? this.task.alphaAgent.id
            : null;
        })
        .catch(() => {
          this.$toast.open({
            message: "Error getting agents",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
          this.loading = false;
        });
    },
    addAgent(agent) {
      this.addedAgents.push(agent._id);
      if (this.addedAgents.length === 1) {
        this.alphaAgentId = agent._id;
      }
    },
    removeAgent(agent) {
      this.addedAgents = this.$_.filter(this.addedAgents, a => a != agent._id);
      this.$nextTick(() => {
        if (this.$_.isEmpty(this.addedAgents)) {
          this.markAsAlpha(null);
        } else if (agent._id === this.alphaAgentId) {
          this.markAsAlpha(this.addedAgents[0]);
        }
      });
    },
    updateAgents() {
      this.processing = true;
      const agentIds = this.addedAgents || [];
      return this.$store
        .dispatch(`tasks/updateAgents`, {
          taskId: this.task._id,
          agentIds: agentIds,
          alphaAgentId: this.alphaAgentId
        })
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: result.message,
            type: "is-success"
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    async confirmUpdate() {
      if (this.user.isAdmin) {
        return await this.updateAgents();
      }

      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: "Save",
          message: `Are you sure you want to save agents ?`
        },
        canCancel: [],
        events: {
          confirmed: async () => {
            await this.updateAgents();
            confirm.close();
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.agent-list {
  overflow-y: auto;
  max-height: 350px;
}
</style>
